*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Acumin Pro, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-1\/2 {
  top: 50%;
}

.bottom-0 {
  bottom: 0;
}

.z-50 {
  z-index: 50;
}

.z-40 {
  z-index: 40;
}

.z-0 {
  z-index: 0;
}

.z-\[1\] {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-\[5\] {
  z-index: 5;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.m-\[8px\] {
  margin: 8px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mr-auto {
  margin-right: auto;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-\[-13\.5rem\] {
  margin-top: -13.5rem;
}

.mt-20, .mt-\[5rem\] {
  margin-top: 5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-\[24\] {
  margin-right: 24px;
}

.mr-\[8\] {
  margin-right: 8px;
}

.mt-\[-4rem\] {
  margin-top: -4rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-12 {
  margin-top: 3rem;
}

.\!mt-16 {
  margin-top: 4rem !important;
}

.\!mt-8 {
  margin-top: 2rem !important;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-\[1rem\] {
  margin-top: 1rem;
}

.mb-\[5rem\] {
  margin-bottom: 5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-1\/2 {
  height: 50%;
}

.h-\[32rem\] {
  height: 32rem;
}

.h-32 {
  height: 8rem;
}

.h-0 {
  height: 0;
}

.h-28 {
  height: 7rem;
}

.h-40 {
  height: 10rem;
}

.h-20 {
  height: 5rem;
}

.h-16 {
  height: 4rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-\[265px\] {
  height: 265px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[440px\] {
  height: 440px;
}

.h-96 {
  height: 24rem;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[20px\] {
  height: 20px;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[85\%\] {
  width: 85%;
}

.w-screen {
  width: 100vw;
}

.w-32 {
  width: 8rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-\[265px\] {
  width: 265px;
}

.w-\[150px\] {
  width: 150px;
}

.w-3\/4 {
  width: 75%;
}

.max-w-\[1400px\] {
  max-width: 1400px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-y-\[3\.5px\] {
  --tw-translate-y: -3.5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  appearance: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.place-items-center {
  place-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[36px\] {
  border-radius: 36px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-\[40px\] {
  border-radius: 40px;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-green-900\/50 {
  border-color: #14532d80;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-\[var\(--color-mediumspringgreen\)\] {
  border-color: var(--color-mediumspringgreen);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-\[var\(--color-mediumblue\)\] {
  background-color: var(--color-mediumblue);
}

.bg-\[var\(--color-whitesmoke\)\] {
  background-color: var(--color-whitesmoke);
}

.bg-\[var\(--color-mediumspringgreen\)\] {
  background-color: var(--color-mediumspringgreen);
}

.bg-\[var\(--color-black\)\] {
  background-color: var(--color-black);
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-3 {
  padding: .75rem;
}

.p-12 {
  padding: 3rem;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pt-24 {
  padding-top: 6rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[1rem\] {
  padding-left: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-termina {
  font-family: Termina, sans-serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.leading-none {
  line-height: 1;
}

.\!leading-loose {
  line-height: 2 !important;
}

.leading-loose {
  line-height: 2;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-\[var\(--color-whitesmoke\)\] {
  color: var(--color-whitesmoke);
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-\[var\(--color-mediumspringgreen\)\] {
  color: var(--color-mediumspringgreen);
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-\[padding\] {
  transition-property: padding;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.placeholder\:font-termina::placeholder {
  font-family: Termina, sans-serif;
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-transparent:hover {
  border-color: #0000;
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-blue-700\/50:hover {
  --tw-shadow-color: #1d4ed880;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-blue-500\/50:hover {
  --tw-shadow-color: #3b82f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-blue-600\/30:hover {
  --tw-shadow-color: #2563eb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-gray-600\/30:hover {
  --tw-shadow-color: #4b55634d;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:border-\[var\(--color-mediumspringgreen\)\]:focus {
  border-color: var(--color-mediumspringgreen);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[200px\] {
    height: 200px;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-\[-12rem\] {
    margin-top: -12rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:ml-\[-15\%\] {
    margin-left: -15%;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-36 {
    height: 9rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-\[28rem\] {
    height: 28rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-\[calc\(100\%_-_126\.83px\)\] {
    height: calc(100% - 126.83px);
  }

  .lg\:h-\[44rem\] {
    height: 44rem;
  }

  .lg\:h-\[24rem\] {
    height: 24rem;
  }

  .lg\:w-\[60\%\] {
    width: 60%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-\[28rem\] {
    width: 28rem;
  }

  .lg\:w-\[22rem\] {
    width: 22rem;
  }

  .lg\:w-\[85\%\] {
    width: 85%;
  }

  .lg\:w-\[115\%\] {
    width: 115%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:rounded-\[32px\] {
    border-radius: 32px;
  }

  .lg\:rounded-\[48px\] {
    border-radius: 48px;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-inherit {
    color: inherit;
  }
}

/*# sourceMappingURL=index.25eb649c.css.map */
